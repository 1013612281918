import { useContext } from "react";
import { AdsContextAppRouter } from "~/c/Contexts/GamContext-approuter";
import { AdsContext } from "~/c/Contexts/GamContext-pagerouter";
import { AdsContextValue } from "~/helpers/Gam/Types";

export const useAdsContext = (): AdsContextValue => {
  const appRouterContext = useContext(AdsContextAppRouter);
  const pagesRouterContext = useContext(AdsContext);

  if (appRouterContext) {
    return appRouterContext;
  }

  if (!pagesRouterContext) {
    throw new Error(
      `[AdsContext]: You forgot to wrap your component with AdsProvider`,
    );
  }
  return pagesRouterContext;
};
