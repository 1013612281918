/* eslint-disable @next/next/no-img-element */
import React, { CSSProperties } from "react";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./DummyBanner.module.scss";
import { ReactElement } from "react-markdown/lib/react-markdown";

interface DummyBannerProps {
  customStyle: CSSProperties;
  className?: string;
  note?: ReactElement;
}

const DummyBanner = (props: DummyBannerProps) => {
  const { customStyle, className, note } = props;

  let imgUrl = "https://fakeimg.pl/100";

  let styleSize = "normal";

  if (customStyle.height === "280px" || className === "largeLB") {
    imgUrl = "https://fakeimg.pl/400x250"; // "https://loremflickr.com/400/250/gothenburg";
    styleSize = "large";
  } else if (className === "wideLB") {
    imgUrl = "https://fakeimg.pl/80";
  } else if (customStyle.minHeight === "114px") {
    styleSize = "small";
  }

  return (
    <div
      style={customStyle}
      className={`${styles[styleSize]} ${styles.adBanner}`}
      data-testid="dummy-ad"
    >
      <img className={styles.adImage} src={imgUrl} alt="Ad Image" />
      <div className={styles.adText}>
        <p className={styles.adTitle}>Dummy banner</p>
        <p className={styles.adDescription}>
          <em>{note}</em>
          {/* and <em>AdSlot {adAttributes && adAttributes["data-ad-slot"]}</em>{" "}
          with{" "}
          <em>
            layoutKey{" "}
            {adAttributes && adAttributes["data-layout-key"]
              ? adAttributes["data-layout-key"]
              : "none"}
          </em> */}
        </p>
        <p className={styles.adUrl}>www.example.com</p>
      </div>
      <button className={styles.ctaButton}>Download</button> {/* CTA Button */}
    </div>
  );
};
export default DummyBanner;
