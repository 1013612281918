import queryString from "query-string";
import { PageTypeReports, SignedImage } from "~/typings/types";

export type UrlEntity =
  | "app-alternatives"
  | "app-about"
  | "app-news"
  | "tag-news"
  | "news-article"
  | "category"
  | "platform"
  | "feature"
  | "subcategory"
  | "user"
  | "search"
  | "tag"
  | "custom-browse"
  | "various"
  | "manage-user"
  | "manage-item"
  | "list-archive"
  | "list"
  | "external";

export const pageTypeToUrlEntity = (pageType: PageTypeReports): UrlEntity => {
  if (pageType === undefined || pageType === null) return "various";

  if (pageType == "AppAlternative") return "app-alternatives";
  if (pageType == "AppAbout") return "app-about";
  if (pageType == "AppNews") return "app-news";
  if (pageType == "ListBrowse") return "list";
  if (pageType == "CustomBrowse") return "custom-browse";
  if (pageType == "MainCategory" || pageType == "MainCategoryAll")
    return "category";

  return pageType.toLowerCase() as UrlEntity;
};

// Only used for share atm.
export const getUrlEntityByUrl = (url: string): UrlEntity => {
  // Regular expressions for the patterns
  const patterns = [
    { pattern: /\/news\/tag\/[^/]+/, value: "tag-news" },
    { pattern: /\/tag\/[^/]+/, value: "tag" },
    { pattern: /\/browse\/new-apps\//, value: "custom-browse" },
    { pattern: /\/category\/[^/]+\/[^/]+/, value: "subcategory" },
    { pattern: /\/category\/[^/]+/, value: "category" },
    { pattern: /\/category\/[^/]+\/all\//, value: "category" },
    { pattern: /\/platform\/[^/]+/, value: "platform" },
    { pattern: /\/feature\/[^/]+/, value: "feature" },
    { pattern: /\/platform\/[^/]+\/\?license=free/, value: "platform" },
    { pattern: /\/software\/[^/]+\/about\//, value: "app-about" },
    { pattern: /\/software\/[^/]+/, value: "app-alternatives" },
    { pattern: /\/software\/[^/]+\/news\//, value: "app-news" },
    { pattern: /\/browse\/search\/[^/]+/, value: "search" },
    { pattern: /\/user\/edit\/+/, value: "manage-user" },
    { pattern: /\/user\/[^/]+/, value: "user" },
    { pattern: /\/manage-add-alternatives\/\?id=[^/]+/, value: "manage-item" },
    { pattern: /\/manage-item\/\?id=[^/]+/, value: "manage-item" },
    { pattern: /\/news\/all\//, value: "news-archive" },
    { pattern: /\/news\/\d{4}\/\d{1,2}\/[^/]+/, value: "news-article" },
    { pattern: /\/lists\/\d+\/[^/]+/, value: "user-list" },
    { pattern: /\/lists\//, value: "list-archive" },
    { pattern: /^(\/|(https:\/\/[^\/]+\/?))$/, value: "startpage" },
  ];

  // Iterate through the patterns and find a match
  for (const { pattern, value } of patterns) {
    if (pattern.test(url)) {
      return value as UrlEntity;
    }
  }

  // If no match is found, return a default value or null
  return "various";
};

export const getUrl = (entity: UrlEntity, fragments: string[]): string => {
  if (!fragments || !fragments[0] || fragments[0].length === 0) {
    return null;
  }
  switch (entity) {
    case "app-alternatives":
      return `/software/${fragments[0]}/`;
    case "app-about":
      return `/software/${fragments[0]}/about/`;
    case "app-news":
      return `/software/${fragments[0]}/news/`;
    case "tag":
      return `/tag/${fragments[0]}/`;
    case "category":
      return `/category/${fragments[0]}/`;
    case "platform":
      return `/platform/${fragments[0]}/`;
    case "feature":
      return `/feature/${fragments[0]}/`;
    case "subcategory":
      return `/category/${fragments[0]}/${fragments[1]}/`;
    case "list":
      return `/list/${fragments[0]}/${fragments[1]}/`;
    default:
      return "";
  }
};

export const getRealUrl = (page: string, query: any): string => {
  let parsedQuerystring;
  let returnUrl;

  switch (page) {
    case "start": {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      parsedQuerystring = queryString.stringify(query);
      returnUrl = `/`;
      break;
    }
    case "user": {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      returnUrl = `/user/${query.userProfile[0]}${
        query.userProfile[1] ? "/" + query.userProfile[1] : ""
      }/`;
      break;
    }
    case "category-main": {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { category, ...rest } = query;
      parsedQuerystring = queryString.stringify(rest);

      returnUrl = `/category/${category}/`;

      break;
    }
    case "alternatives": {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { urlName, toid, ...rest } = query;
      parsedQuerystring = queryString.stringify(rest);
      returnUrl = `/software/${urlName}/`;
      break;
    }
    case "app-news": {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { urlName } = query;
      // parsedQuerystring = queryString.stringify(rest);
      returnUrl = `/software/${urlName}/news/`;
      break;
    }
    case "tag-news": {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { tag } = query;
      // parsedQuerystring = queryString.stringify(rest);
      returnUrl = `/news/tag/${tag}/`;
      break;
    }
    case "list": {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, urlname } = query;
      returnUrl = `/list/${id}/${urlname}/`;
      break;
    }
    case "browse": {
      // Take all querystring except browse and appList.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { browse, appList, ...rest } = query;

      if (browse == "new-apps" || browse == "recently-discontinued") {
        parsedQuerystring = queryString.stringify(rest);
        returnUrl = `/browse/${browse}/`;
      } else {
        parsedQuerystring = queryString.stringify(rest);
        returnUrl = `/${browse}/${appList.join("/")}/`;
      }
      break;
    }
    case "news-archive": {
      // Take all querystring except browse and appList.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      parsedQuerystring = queryString.stringify(query);
      returnUrl = `/news/all/`;
      break;
    }
    case "search": {
      // Take all querystring except browse and appList.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { searchQuery, ...rest } = query;
      // parsedQuerystring = queryString.stringify(rest);
      returnUrl = `/browse/search/?query=` + searchQuery;
      break;
    }
  }

  return parsedQuerystring ? returnUrl + "?" + parsedQuerystring : returnUrl;
};

export const getAffiliateUrl = (urlName: string): string => {
  return `/outgoing/software/${urlName}`;
};

export const getUrlNameFromAffiliateUrl = (url: string): string => {
  const match = url.match(/\/outgoing\/software\/([^/]+)/);
  return match ? match[1] : "";
};

export const getUrlNameFromUrl = (url: string): string => {
  const match = url.match(/\/software\/([^/]+)/);
  return match ? match[1] : "";
};

/**
 *
 * @param url - current url
 * @param currentPage - current page number
 * @param page - 'prev' or 'next' or a number
 */
export const createPaginationURL = (
  url: string,
  currentPage: number,
  page: string | number,
): string => {
  let newUrl = url;
  let linkedPage = page;

  if (currentPage === page) {
    return url;
  }

  if ((linkedPage as number) < 1) {
    return url;
  }

  if (page === "prev") {
    linkedPage = currentPage - 1;
  } else if (page === "next") {
    linkedPage = currentPage + 1;
  }

  const [baseUrl, queryString] = url.split("?");

  const searchParams = new URLSearchParams(
    queryString ? "?" + queryString : "",
  );

  newUrl = addTrailingSlash(baseUrl);

  if (linkedPage === 1) {
    searchParams.delete("p");
  } else {
    searchParams.set("p", linkedPage.toString());
  }

  if (searchParams.toString()) {
    newUrl += "?" + searchParams.toString();
  }

  return newUrl;
};

export const getListImageUrl = (
  fileName: string,
  requestedWidth: number,
  requestedHeight: number,
) => {
  //const formatSuffix = getWebpSupport(false);

  const imageUrl = `https://d2.alternativeto.net/dist/listheaders/${fileName}?format=jpg&width=${requestedWidth}&height=${requestedHeight}&mode=crop`;

  return imageUrl;
};

export const getScreenshotUrl = (
  fileName: string,
  signedImages?: SignedImage[],
  aspectType: string = "landscape-new",
  aspectTypePrefix: string = "",
) => {
  const httpPattern = /^((http|https):\/\/)/;

  if (signedImages) {
    let img = signedImages.find(
      (x) => x.size === aspectTypePrefix + aspectType,
    );
    if (img) return img.signedURL;

    img = signedImages.find((x) => x.size === aspectType);

    if (img) return img.signedURL;

    img = signedImages.find((x) => x.size === "landscape-new");

    if (img) return img.signedURL;
  }

  if (httpPattern.test(fileName)) {
    return fileName;
  }
};

export const editItemUrl = (id, showSteps?: string) => {
  let url = `/manage-item/?id=${id}`;

  if (showSteps) {
    url += `&showSteps=${showSteps}`;
  }

  return url;
};

export const replaceHost = (url: string, noHost?: boolean) => {
  const DEPLOY_ENV = process.env.DEPLOY_ENV;

  let returnUrl = url;

  if (url.indexOf("alternativeto.net") > 0) {
    if (DEPLOY_ENV == "beta") {
      returnUrl = url.replace("alternativeto.net", "beta.alternativeto.net");
    } else if (DEPLOY_ENV == "development") {
      returnUrl = url.replace("alternativeto.net", "local.alternativeto.net");
    } else if (DEPLOY_ENV == "test") {
      returnUrl = url.replace("alternativeto.net", "local.alternativeto.net");
    }

    if (noHost) {
      returnUrl = url.replace(/(http)?s?:?\/\/alternativeto.net/, "");
    }
  } else {
    if (!returnUrl.startsWith("/")) {
      returnUrl = "/" + returnUrl;
    }
    if (!noHost) {
      returnUrl = "https://alternativeto.net" + returnUrl;
    }
  }

  return addTrailingSlash(returnUrl);
};

export const removeURLParameter = (url, parameter) => {
  //prefer to use l.search if you have a location/link object
  const urlparts = url.split("?");
  if (urlparts.length >= 2) {
    const prefix = encodeURIComponent(parameter) + "=";
    const pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (let i = pars.length; i-- > 0; ) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
  }
  return url;
};

export const removeFilterParameter = (url: string, value?: string) => {
  const [baseUrl, queryString] = url.split("?");
  const params = new URLSearchParams(queryString);

  const param = Array.from(params.entries()).find(([, paramValue]) =>
    paramValue.includes(value),
  );
  if (param) {
    const [name, paramValue] = param;
    if (paramValue.includes(",")) {
      const values = paramValue.split(",");
      const updatedValues = values.filter((v) => v !== value);
      if (updatedValues.length > 0) {
        params.set(name, updatedValues.join(","));
      } else {
        params.delete(name);
      }
    } else {
      params.delete(name);
    }
  }

  if (params.toString() === "") {
    return baseUrl;
  } else {
    return `${baseUrl}?${params.toString()}`;
  }
};

export const getBaseUrl = (url) => {
  //prefer to use l.search if you have a location/link object
  const urlparts = url.split("?");
  if (urlparts.length >= 2) {
    return urlparts[0];
  }
  return url;
};

export const removeHttpAndSimilarFromString = (link: string) => {
  return (
    link &&
    link.replace("http://", "").replace("https://", "").replace("www.", "")
  );
};

export function addTrailingSlash(newUrl: string) {
  if (!newUrl) return "";

  if (
    !newUrl.endsWith("/") &&
    newUrl.indexOf("?") === -1 &&
    newUrl.indexOf("&") === -1
  ) {
    newUrl = newUrl + "/";
  }

  if (newUrl.substr(newUrl.indexOf("?") - 1, 1) !== "/") {
    newUrl = newUrl.replace("?", "/?");
  }
  return newUrl;
}

export function addQueryParameter(url: string, param: string) {
  if (!url) return "";

  const [baseUrl, queryString] = url.split("?");
  const currentParams = new URLSearchParams(queryString);
  const [name, value] = param.split("=");

  const currentValue = currentParams.get(name);

  if (currentParams.has(name)) {
    if (name === "feature" || name === "platform") {
      if (value !== currentValue) {
        currentParams.set(name, value + "," + currentValue);
      }
    } else {
      currentParams.set(name, value);
    }
  } else {
    currentParams.append(name, value);
  }

  return `${baseUrl}?${currentParams.toString()}`;
}

export function getPostHashId(url: string) {
  if (!url) return null;

  const splitHash = url.split("#post-");

  if (splitHash.length > 1) {
    return splitHash[1];
  }

  return null;
}

export const buildSortUrl = (
  baseUrl: string,
  currentSort: string,
  newSort: string,
  currentDir?: string,
) => {
  const dummyUrl = "https://dummy.com";
  const url = new URL(dummyUrl + baseUrl);

  if (newSort === undefined) {
    newSort = "views";
  }
  // Only add the sort parameter if it's different from the default
  if (newSort !== "views") {
    url.searchParams.set("sort", newSort);
  }

  if (currentDir !== undefined) {
    // If the sort parameter is the same and the current direction is 'desc', set the direction to 'asc'
    if (newSort === currentSort && currentDir === "desc") {
      url.searchParams.set("sortDir", "asc");
    }
    // If the sort parameter has changed and the current direction is 'asc', set the direction to 'desc'
    else if (newSort !== currentSort && currentDir === "asc") {
      url.searchParams.set("sortDir", "desc");
    }
    // If the sort parameter is the same and the current direction is 'asc', or the sort parameter has changed and the current direction is 'desc', omit the sortDir parameter
    else if (
      (newSort === currentSort && currentDir === "asc") ||
      (newSort !== currentSort && currentDir === "desc")
    ) {
      url.searchParams.delete("sortDir");
    }
  }

  return url.toString().replace(dummyUrl, "");
};

// add a function that gets an url and only return the main host name including https or http
export const getHostName = (url: string) => {
  if (!url) return "";
  const parsedUrl = new URL(url);
  const protocol = parsedUrl.protocol;
  const host = parsedUrl.hostname;
  return `${protocol}//${host}`;
};
