"use client";

import React, {
  CSSProperties,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./PageIntroWrapper.module.scss";
import { RootContextValues } from "~/typings/types";
import { RootContext } from "~/c/Contexts/RootContext";

type PageIntroWrapperProps = {
  children: any;
  noClouds?: boolean;
  customStyles?: CSSProperties;
  Ad?: React.ReactNode;
};

const PageIntroWrapper = (props: PageIntroWrapperProps) => {
  const { children, noClouds, customStyles, Ad } = props;

  const [inView, setInView] = useState(true);

  const rootContext = useContext<RootContextValues>(RootContext);

  const introWrapper = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        rootContext.setIntroWrapperInView(entry.isIntersecting);
        setInView(entry.isIntersecting);
      },
      { rootMargin: "-50px" },
    );

    observer.observe(introWrapper.current);

    return () => observer.disconnect();
  }, [inView]);

  return (
    <div
      data-testid="introWrapper"
      ref={introWrapper}
      style={customStyles}
      className={`blue-box ${styles.wrapper} ${
        styles.svgBackground ? styles.svgBackground : ""
      } ${noClouds ? styles.noClouds : ""}`}
    >
      {Ad && <div className={styles.foodWrapper}>{Ad}</div>}
      <div className={`container`}> {children}</div>
    </div>
  );
};

export default PageIntroWrapper;
