/**
 * Put public, non-secret constants in this file. Do *not* put
 * passwords, API-keys and other secret things here. Those things
 * should still live in environment variables.
 */

export const AD_SLOTS = {
  ATF1: "1411151238",
  BROWSE_ATF1: "1621641224",
  BROWSE_ATF2: "1359725263",
  //ATF1_NATIVE: "7766420786",
  APPLIST_NATIVE: "6386831870",
  APPLIST_NATIVE_EXPERIMENT: "8973908283",
  APPLIST_NATIVE_RIGHT_IMAGE: "9687324735",
  APPLIST_NATIVE_DARK: "5760301051",
  ATF2: "2887884433",
  BTF: "3010478831",
  SIDEBAR: "2338213836",
  NEWS_AUTORELAXED: "7086886591",
  NEWS_FIXED: "6682262760",
};

export const AD_LAYOUT_KEY = {
  APPLIST_NATIVE_DEFAULT: "-gk-1w+1m-ap+rg",
  APPLIST_NATIVE_EXPERIMENT: "-g9-1i-s-cb+125",
  APPLIST_NATIVE_DARK: "-gl-1m+t-bf+ub",
  APPLIST_NATIVE_RIGHT_IMAGE: "-ez-45+h3+3d-1f5",
};

// AppPage - Alternatives - ATF1 - Dynamic;
// 2171313326;
// Active;
// ca - pub - 8332396259212490(content);
// AppPage - Alternatives - ATF1 - Regular;
// 3292823301;

export const AD_CHANNELS = {
  ATF1: "7884340307",
  STARTPAGE: "5062439780",
  STARTPAGE_BTF1: "9906954224",
  STARTPAGE_BTF2: "5844420283",
  STARTPAGE_BTF3: "8593872559",
  APP_PAGE_NEW: "3354540087",
  APP_PAGE_ATF1: "6415817693",
  APP_PAGE_ATF2: "4911164336",
  //APP_PAGE_SIDEBAR: "3287209134",
  APP_PAGE_ABOUT: "8890771944",
  APP_PAGE_ABOUT_ATF1: "5143098627",

  //APP_PAGE_ABOUT_ATF1_NATIVE: "6042275579",
  //APP_PAGE_ABOUT_ATF1_DISPLAY: "6424013988",
  APP_PAGE_ABOUT_ATF2: "6937142933",
  // APP_PAGE_ABOUT_ATF2_TEST2: "1946677928",
  // APP_PAGE_ABOUT_ATF2_TEST3: "5867926859",
  // APP_PAGE_ABOUT_ATF2_TEST5: "2803234068",
  // APP_PAGE_ABOUT_SIDEBAR: "6208094519",
  APP_PAGE_ABOUT_BTF: "1728318670",
  APP_PAGE_ALTERNATIVES: "2824057043",
  APP_PAGE_ALTERNATIVES_ATF1: "6073036917",
  APP_PAGE_ALTERNATIVES_ATF2: "8507628566",
  APP_PAGE_ALTERNATIVES_REGULAR: "8880871495",
  APP_PAGE_ALTERNATIVES_EXPERIMENT: "9264014871",
  APP_PAGE_ALTERNATIVES_BTF2_REGULAR: "1497205498",
  APP_PAGE_ALTERNATIVES_BTF2_EXPERIMENT: "3048609243",
  APP_PAGE_ALTERNATIVES_ATF2_REGULAR: "7611720539",
  APP_PAGE_ALTERNATIVES_ATF2_MOBILE: "8523109426",
  APP_PAGE_ALTERNATIVES_ATF2_EXPERIMENT: "3931797140",
  APP_PAGE_ALTERNATIVES_BTF_EXPERIMENT: "6894539859",
  APP_PAGE_ALTERNATIVES_BTF_REGULAR: "1256281935",
  APP_PAGE_ALTERNATIVES_BTF: "6590738204",
  APP_PAGE_ALTERNATIVES_BTF2: "1956978063",
  APP_PAGE_ATF2_NATIVE: "6292604436",
  APP_PAGE_BTF: "5069772973",
  APP_PAGE_BTF2: "2779105147",
  ATF2: "8793283302",
  BTF: "7457379272",
  BTF2: "6200293642",
  BROWSE: "3010991514",
  BROWSE_ATF1: "5823065106",
  BROWSE_ATF2: "5401706359",
  //BROWSE_ATF2_ORIGINAL: "4518904327",
  //BROWSE_ATF2_TEST: "2474075350",
  BROWSE_APP_LIST: "8645705803",
  BROWSE_APP_LIST_ORIGINAL: "2080297452",
  BROWSE_APP_LIST_TEST1: "8454134119",
  BROWSE_BTF: "6962555253",
  BROWSE_BTF2: "7037110664",
  SIDEBAR: "7532620643",
  //THEME_DARK: "2900088013",
  //THEME_LIGHT: "4575636924",
  THEME_CLASSIC: "7637409375",
  NEWS: "8666711513",
  NEWS_FIXED: "2306783928",
  MOBILE: "5153913895",
};
