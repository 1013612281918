"use client";

import React, { useEffect, useState } from "react";

import { AdsContextValue, AdsProviderComponent } from "~/helpers/Gam/Types";
import { dfp } from "~/helpers/Gam/Api";
import { usePathname } from "next/navigation";

export const AdsContextAppRouter = React.createContext<AdsContextValue>(null);

export const AdsProviderAppRouter: AdsProviderComponent = ({
  ads,
  children,
  debug = false,
  enableLazyload = true,
  enableRefresh = false,
  enabled = true,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const pathname = usePathname();
  //const searchParams = useSearchParams();

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setIsLoading(true);
      dfp.removeSlots();
    }, 20);

    const timer2 = setTimeout(() => {
      dfp.createSlots(ads, enableLazyload, enableRefresh);
      setIsLoading(false);
    }, 70);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [pathname, ads, enableLazyload, enableRefresh]);

  // Enable debug console if possible
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (!!debug && !searchParams.has("google_console")) {
      searchParams.append("google_console", "1");
      window.location = `${window.location.pathname}?${searchParams}` as any;
    }

    if (!debug && searchParams.has("google_console")) {
      searchParams.delete("google_console");

      const search = `${searchParams}`.length > 0 ? `?${searchParams}` : "";
      window.location = `${window.location.pathname}${search}` as any;
    }
  }, [debug]);

  return (
    <>
      <AdsContextAppRouter.Provider value={{ isLoading }}>
        {children}
      </AdsContextAppRouter.Provider>
      {enabled && (
        <script
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
          async
        />
      )}
    </>
  );
};
