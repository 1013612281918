import { AD_CHANNELS } from "~/constants";

export const GetAdsChannelString = (channels: string) => {
  const channelsArray = channels.split(",");
  const channelPlainTextArray: string[] = [];

  channelsArray.map((v) => {
    channelPlainTextArray.push(getKeyByValue(AD_CHANNELS, v));
  });

  const channelString = channelPlainTextArray.join(", ");
  return channelString;
};

const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const showRealAds = () => {
  const DEPLOY_ENV = process.env.DEPLOY_ENV;
  const showRealAds = DEPLOY_ENV === "production";

  //return true;

  return showRealAds;
};

export const shouldShowConsent = (url: string): boolean => {
  if (url === null || url === undefined) {
    return true;
  }

  if (
    url.indexOf("https://alt2-frontend-web-test.azurewebsites.net") > -1 ||
    url.indexOf("https://dev.alternativeto.net") > -1 ||
    url.indexOf("https://beta.alternativeto.net") > -1
  ) {
    return false;
  }

  return true;
};
