"use client";
import React, { useContext } from "react";
import { AdPlacementProps, RootContextValues } from "~/typings/types";

import { RootContext } from "~/c/Contexts/RootContext";
import { AdItem, SizeMappings } from "~/helpers/Gam/Types";
import { GamAd } from "~/c/Ads/GamAd";

const largeLBSizeMapping: SizeMappings = [
  {
    breakpoint: [1200, 100],
    sizes: [[1176, 280]],
  },
  {
    breakpoint: [1024, 100],
    sizes: [[970, 280]],
  },
  {
    breakpoint: [800, 100],
    sizes: [[728, 90]],
  },
  {
    breakpoint: [0, 0],
    sizes: [[336, 280]],
  },
];

const largeLBSizeMappingAlternative: SizeMappings = [
  {
    breakpoint: [1200, 100],
    sizes: [[1176, 280]],
  },
  {
    breakpoint: [1024, 100],
    sizes: [[970, 280]],
  },
  {
    breakpoint: [800, 100],
    sizes: [[728, 90]],
  },
  {
    breakpoint: [0, 0],
    sizes: [[300, 600]],
  },
];

const wideLBSizeMappings: SizeMappings = [
  {
    breakpoint: [1200, 100],
    sizes: [
      [1176, 90],
      [970, 90],
    ],
  },
  {
    breakpoint: [1024, 100],
    sizes: [[970, 90]],
  },
  {
    breakpoint: [800, 100],
    sizes: [[728, 90]],
  },
  {
    breakpoint: [0, 0],
    sizes: [[300, 250]],
  },
];

// const nativeAppListMappings: SizeMappings = [
//   {
//     breakpoint: [1200, 100],
//     sizes: [
//       [1176, 114],
//       [970, 114],
//     ],
//   },
//   {
//     breakpoint: [1024, 100],
//     sizes: [[970, 114]],
//   },
//   {
//     breakpoint: [800, 100],
//     sizes: [[728, 90]],
//   },
//   {
//     breakpoint: [0, 0],
//     sizes: [[300, 250]],
//   },
// ];

export const ads: AdItem[] = [
  {
    slotId: "/1003634/About_ATF1",
    sizeMappings: largeLBSizeMapping,
    divId: "AboutATF1",
  },
  {
    slotId: "/1003634/ATF2_AboutPage",
    sizeMappings: wideLBSizeMappings,
    divId: "AboutBTF",
  },
  {
    slotId: "/1003634/About_ATF2",
    sizeMappings: largeLBSizeMapping,
    divId: "AboutATF2",
  },
  {
    slotId: "/1003634/Browse_ATF2_Responsive",
    sizeMappings: largeLBSizeMapping,
    divId: "BrowseATF2",
  },
  {
    slotId: "/1003634/Alternative_ATF1",
    sizeMappings: largeLBSizeMapping,
    divId: "AlternativeATF1",
  },
  {
    slotId: "/1003634/Browse_ATF1",
    sizeMappings: wideLBSizeMappings,
    divId: "BrowseATF1",
  },
  {
    slotId: "/1003634/News-336x280",
    sizeMappings: [336, 280],
    divId: "NewsSidebar",
  },
  {
    slotId: "/1003634/Browse_BTF",
    sizeMappings: largeLBSizeMapping,
    divId: "BrowseBTF",
  },
  {
    slotId: "/1003634/Browse_BTF2",
    sizeMappings: largeLBSizeMapping,
    divId: "BrowseBTF2",
  },
  {
    slotId: "/1003634/Startpage_BTF1",
    sizeMappings: largeLBSizeMapping,
    divId: "StartpageBTF1",
  },
  {
    slotId: "/1003634/Startpage_BTF2",
    sizeMappings: largeLBSizeMapping,
    divId: "StartpageBTF2",
  },
  {
    slotId: "/1003634/Startpage_BTF3",
    sizeMappings: largeLBSizeMapping,
    divId: "StartpageBTF3",
  },
  {
    slotId: "/1003634/Category_ATF1",
    sizeMappings: largeLBSizeMapping,
    divId: "CategoryATF1",
  },
  {
    slotId: "/1003634/Category_ATF2",
    sizeMappings: largeLBSizeMapping,
    divId: "CategoryATF2",
  },
  {
    slotId: "/1003634/AlternativeList_BTF1",
    sizeMappings: largeLBSizeMappingAlternative,
    divId: "AlternativeListBTF1",
  },
  {
    slotId: "/1003634/AlternativeList_BTF2",
    sizeMappings: largeLBSizeMapping,
    divId: "AlternativeListBTF2",
  },
  {
    slotId: "/1003634/AlternativeList_ATF2",
    sizeMappings: largeLBSizeMapping,
    divId: "AlternativeListATF2",
  },
];

const AdPlacement = (props: AdPlacementProps) => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    randomABTest,
    isAdsenseBlocked,
    placement,
    overrideMobile,
    pageType,
    mainAppType,
    itemAlerts,
  } = props;

  const rootContext = useContext<RootContextValues>(RootContext);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const abTest = randomABTest ? randomABTest : rootContext.randomABTestSession;

  const isMobile = overrideMobile ? overrideMobile : rootContext.isMobile;

  // let adSlot = "";
  // let format = "";
  let className;
  let outerStyle = {};
  // let innerStyle: CSSProperties = null;
  // let channels = "";
  // let layoutKey: string = undefined;
  // let lineOver = false;
  // let lineUnder = false;

  let gamId = "";

  if (
    placement === "AboutATF1" ||
    placement === "AboutATF2" ||
    placement === "StartpageBTF1" ||
    placement === "StartpageBTF2" ||
    placement === "StartpageBTF3" ||
    placement === "CategoryATF1" ||
    placement === "CategoryATF2" ||
    placement === "AlternativeATF1" ||
    placement === "AlternativeListBTF2" ||
    placement === "AlternativeListATF2" ||
    placement === "BrowseBTF" ||
    placement === "BrowseBTF2" ||
    placement === "BrowseATF2"
  ) {
    gamId = placement;
    className = "largeLB";
  } else if (placement === "AboutBTF") {
    gamId = "AboutBTF";
    className = "wideLB";
  } else if (placement === "AlternativeListBTF1") {
    gamId = placement;
    className = "largeLB";
  } else if (placement === "BrowseATF1") {
    gamId = "BrowseATF1";
    if (isMobile) {
      outerStyle = { minWidth: "300px", minHeight: "250px" };
    } else {
      className = "wideLB";
    }
  } else if (placement === "NewsSidebar") {
    gamId = "NewsSidebar";
    outerStyle = { minWidth: "336px", minHeight: "280px" };
  }

  return (
    <GamAd
      id={gamId}
      width={"auto"}
      height={"auto"}
      style={outerStyle}
      pageType={pageType}
      className={className ? className : undefined}
      isAdsenseBlocked={isAdsenseBlocked}
      mainAppType={mainAppType}
      itemAlerts={itemAlerts}
    ></GamAd>
  );
};
export default AdPlacement;
