"use client";

import React, { useContext, useEffect, useState } from "react";
import { dfp } from "~/helpers/Gam/Api";
import { AdComponent } from "~/helpers/Gam/Types";

import styles from "./GamAds.module.scss";
import { RootContext } from "~/c/Contexts/RootContext";
import { RootContextValues } from "~/typings/types";
import { showRealAds } from "~/helpers/AdsHelper";
import DummyBanner from "~/c/Ads/DummyBanner";
import { useAdsContext } from "~/c/Contexts/useAdsContext";

export const GamAd: AdComponent = ({
  id,
  className,
  style = {},
  width = 350,
  height = 250,
  isAdsenseBlocked = false,
  pageType,
  mainAppType,
  itemAlerts,
}) => {
  const { isLoading } = useAdsContext();

  const [showDebugBar, setShowDebugBar] = useState(false);
  const rootContext = useContext<RootContextValues>(RootContext);

  const showReal = showRealAds();

  useEffect(() => {
    if (window?.location?.hostname === "local.alternativeto.net") {
      setShowDebugBar(true);
    }

    if (!isLoading && !!id && showReal) {
      dfp.setTargeting("PageType", pageType);
      dfp.setTargeting("adsense_blocked", isAdsenseBlocked ? "1" : "0");

      if (mainAppType) {
        dfp.setTargeting("AppType", mainAppType);
      }

      if (itemAlerts && itemAlerts.length > 0) {
        // map item alerts to targeting
        itemAlerts.forEach((alert) => {
          dfp.setTargeting(`ItemAlert`, alert.type);
        });
      }

      if (showReal) {
        dfp.showSlot(id);
      }
    }
  }, [isLoading, id, showReal]);

  const dummyNote = (
    <>
      GAM Banner: <em>{id}</em>
      <br></br>Style: {JSON.stringify(style)}, ClassName: <em>{className}</em>
    </>
  );

  if (isAdsenseBlocked) {
    return <></>;
  }

  return (
    <div style={{ ...style }} className={styles.food}>
      {showReal ? (
        <>
          {(rootContext.isCrewAdmin || showDebugBar) && (
            <div className={styles.liveDebugChannels}>
              AdManager Ad {id} {width}x{height}
            </div>
          )}
          <div
            id={id}
            className={styles[className]}
            style={{
              width,
              height,
            }}
          />
        </>
      ) : (
        <div className={styles[className]}>
          <DummyBanner
            note={dummyNote}
            className={className}
            customStyle={{
              width,
              height,
            }}
          ></DummyBanner>
        </div>
      )}
    </div>
  );
};
