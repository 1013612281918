import queryString from "query-string";
import * as next from "next";
import { PageMeta, PageRequiredData } from "~/typings/types";

import { addTrailingSlash } from "./urls";
import retry from "promise-fn-retry";

import NodeCache from "node-cache";
import { fortKnoxValue } from "~/helpers/globalConstants";

const memoryCache = new NodeCache({
  forceString: true,
  stdTTL: 3600, // Default TTL in seconds
  checkperiod: 600, // Cleanup every 10 minutes
});

export type HTTPMethod = "GET" | "PUT" | "POST" | "DELETE" | "UPDATE" | "PATCH";

export interface FetchOptions {
  method: HTTPMethod;
  headers?: {
    [key: string]: string;
  };
  body?: string | FormData;
  signal?: any;
  next?: NextFetchRequestConfig;
}

export const getCacheStats = (): any => {
  return memoryCache.getStats();
};

export const getCacheKeys = (): any => {
  return memoryCache.keys();
};

export const flushCache = () => {
  memoryCache.flushAll();
  memoryCache.flushStats();
};

export const getCacheValue = (key: string): any => {
  return memoryCache.get(key);
};

export const removeCacheValue = (key: string): any => {
  return memoryCache.del(key);
};

/**
 * Returns the right base URL for the API depending on the
 * current node environment
 */
export const getBaseURLforAPI = (serverOrClient: string): string => {
  const DEPLOY_ENV = process.env.DEPLOY_ENV;
  const AUTH0_BASE_URL = process.env.AUTH0_BASE_URL;

  let apiUrl = AUTH0_BASE_URL + "/api/proxy";

  const isServer = serverOrClient === "server";

  if (isServer) apiUrl = "http://localhost:5000/api";

  if (DEPLOY_ENV === "test" && isServer) {
    apiUrl = "https://alt2-frontend-api-linux-test.azurewebsites.net/api";
  }
  if (DEPLOY_ENV === "beta" && isServer) {
    apiUrl = "https://alt2-frontend-api-linux-staging.azurewebsites.net/api";
  }

  if (DEPLOY_ENV === "production" && isServer) {
    apiUrl = "https://alt2-frontend-api-linux.azurewebsites.net/api";
  }

  return apiUrl;
};

/**
 * Returns a URL to the API including the parameters
 *
 * @param {string} endpoint - The APIs endpoint
 * @param {object} params - Optional parameters
 * @param {string} overrideBaseURL - Override the baseUrl for the API
 */
export const constructURLforAPI = (
  endpoint: string,
  params?: Record<string, any>,
  baseUrl?: string,
): string => {
  let url = baseUrl + endpoint;

  // If params were passed, add them to the URL
  if (params && Object.keys(params).length > 0) {
    url += "?" + queryString.stringify(params);
  }

  return url;
};

export const getContentTypeHeader = (
  method: HTTPMethod,
): {
  [key: string]: string;
} => {
  return method === "POST" || method === "PUT" || method === "DELETE"
    ? { "Content-Type": "application/json" }
    : {};
};

/**
 * Returns an object containing the fort-knox header
 */
export const getFortKnoxHeader = (
  userId = "0",
): {
  [key: string]: string;
} => {
  const value = Buffer.from(
    Math.floor(Date.now() / 1000).toString() + "|" + userId,
  ).toString("base64");

  // const valueOld = Buffer.from(
  //   Math.floor(Date.now() / 1000).toString()
  // ).toString("base64");

  return {
    "fort-knox": value,
    cookie: `fort-knox=` + fortKnoxValue,
  };
};

/**
 * Returns an object containing the fort-knox header
 */
export const getCacheHeader = (
  userId,
): {
  [key: string]: string;
} => {
  if (!userId) {
    return {
      "cache-control": "public, max-age=600",
    };
  } else {
    return { "cache-control": "no-cache" };
  }
};

/**
 * Returns an object containing the Authorization header
 *
 * @param accessToken - Current user's access token
 */
export const getAuthorizationHeader = (accessToken?: string): any =>
  accessToken
    ? { Authorization: "Bearer " + (accessToken ? accessToken : "") }
    : {};

/**
 * Returns an object containing the Recaptcha header
 *
 * @param recaptchaToken - Current user's recaptcha token
 */
const getRecaptchaHeader = (recaptchaToken?: string): any =>
  recaptchaToken ? { Recaptcha: recaptchaToken ? recaptchaToken : "" } : {};

/**
 * Returns a unique cache key for the current request
 *
 * @param url - URL for the API call
 * @param options - Options object for the entire API call
 */
export const getCacheKey = (url: string, options: FetchOptions): string => {
  // Deep clone object: https://stackoverflow.com/a/5344074
  const clonedOptions = JSON.parse(JSON.stringify(options));

  // Remove fort knox header
  if (clonedOptions["headers"]) {
    delete clonedOptions["headers"]["fort-knox"];
    delete clonedOptions["headers"]["Authorization"];
    delete clonedOptions["headers"]["cookie"];
  }

  // If that leaves an empty headers object,
  // remove it all together
  if (
    clonedOptions["headers"] &&
    Object.keys(clonedOptions["headers"]).length === 0
  ) {
    delete clonedOptions["headers"];
  }

  return url + JSON.stringify(clonedOptions);
};

/**
 * Retrieves the user's IP address from next.js's context
 *
 * @param ctx - Next.js context
 */
// export const getIP = (ctx: next.NextPageContext | void): string => {
//   let ip = "";
//   if (ctx && ctx.req && ctx.req.headers && ctx.req.headers["x-forwarded-for"]) {
//     const xForwardedForHeaders = ctx.req.headers["x-forwarded-for"];
//     if (typeof xForwardedForHeaders === "string") {
//       ip = xForwardedForHeaders;
//     }
//   }
//   return ip;
// };

/**
 * Check if the URL is cachable
 */
export const isCachable = (
  url: string,
  access_token: string,
  disableCache: boolean,
): number => {
  if (url.indexOf("/api/proxy/") > -1) {
    return 0;
  }

  if (disableCache) {
    return 0;
  }

  if (url.indexOf("/metadata/") > -1) {
    return 0;
  }

  const cacheForever = [
    "/api/categories",
    "/api/platforms/types",
    "/api/platforms",
  ];

  if (cacheForever.find((cachedUrl) => url.indexOf(cachedUrl) > 0)) {
    return 100 * 60;
  }

  if (access_token) {
    return 0;
  }

  const cacheLimited = [
    "/api/items",
    "/api/news",
    "/api/meta/page",
    "/api/lists",
    "/api/users/top-contributors",
    "/api/users/id/",
    "/api/activities",
  ];

  if (cacheLimited.find((cachedUrl) => url.indexOf(cachedUrl) > 0)) {
    return 0;
    //return 20 * 60;
  }

  return 0;
};

/// Not 100% sure why this is needed. But it's used for posting form data.
export const postAPIFormClientSide = (
  endpoint: string,
  method: HTTPMethod = "POST",
  body: FormData,
  userId: string,
): Promise<any> => {
  const fortKnoxHeader = getFortKnoxHeader(userId);

  const API_BASE_URL = getBaseURLforAPI("client");

  const url = constructURLforAPI(endpoint, null, API_BASE_URL);

  const options: FetchOptions = {
    method,
    headers: {
      ...fortKnoxHeader,
    },
    body: body,
  };

  return fetch(url, options);
};

export const callAPIServerSide = (
  endpoint: string,
  accessToken?: string,
  params?: Record<string, any>,
  method: HTTPMethod = "GET",
  body?: any | string,
  disableCache = false,
): Promise<any> => {
  const API_BASE_URL = getBaseURLforAPI("server");

  return callAPI(
    endpoint,
    params,
    accessToken,
    API_BASE_URL,
    method,
    body,
    disableCache,
  );
};

export const callAPIClientSide = async (
  endpoint: string,
  params?: Record<string, any>,
  method: HTTPMethod = "GET",
  userId?: string,
  body?: any | string,
  recaptchaToken?: string,
): Promise<any> => {
  const API_BASE_URL = getBaseURLforAPI("client");

  try {
    return callAPI(
      endpoint,
      params,
      null,
      API_BASE_URL,
      method,
      body,
      false,
      recaptchaToken,
      userId,
    );
  } catch (err) {
    console.error(err);
    throw err; // Re-throw the error so it can be caught and handled by the calling code
  }
};

const callAPI = (
  endpoint: string,
  params?: Record<string, any>,
  accessToken?: string,
  baseURL?: string,
  method: HTTPMethod = "GET",
  body?: any | string,
  disableCache = false,
  recaptchaToken?: string,
  userId?: string,
): Promise<any> => {
  const contentTypeHeader = getContentTypeHeader(method);

  const fortKnoxHeader = getFortKnoxHeader(userId);

  const recaptchaHeader = recaptchaToken
    ? getRecaptchaHeader(recaptchaToken)
    : {};

  const authorizationHeader = accessToken
    ? getAuthorizationHeader(accessToken)
    : {};

  const url = constructURLforAPI(endpoint, params, baseURL);

  let timeoutValue = 15000;

  if (method === "POST" || method === "PUT" || method === "DELETE") {
    timeoutValue = 40000;
  }

  if (endpoint.indexOf("/activities") > -1) {
    timeoutValue = 40000;
  }

  const DEPLOY_ENV = process.env.DEPLOY_ENV;
  const CACHE_MODE = process.env.CACHE_MODE;

  let options: FetchOptions = {
    method,
    headers: {
      ...fortKnoxHeader,
      ...authorizationHeader,
      ...contentTypeHeader,
      ...recaptchaHeader,
    },
    body: body ? JSON.stringify(body) : null,
  };

  if (DEPLOY_ENV !== "development") {
    options = {
      ...options,
      signal: AbortSignal.timeout && AbortSignal.timeout(timeoutValue),
    };
  }

  let useCache = false;
  //let useMemoryCache = false;

  // Only cache specific endpoints
  const cacheDuration = isCachable(url, accessToken, disableCache);

  // If cache duration is -1 or larger than 0.
  if (cacheDuration !== 0) {
    useCache = true;
  }

  if (method !== "GET") {
    useCache = false;
  }

  if (DEPLOY_ENV === "development" && CACHE_MODE && CACHE_MODE === "disabled") {
    // console.info(
    //   "\x1b[31m",
    //   `API: CACHE IS MANUALLY DISABLED FOR => ${url}`,
    //   "\x1b[0m"
    // );
    useCache = false;
  }

  // Create unique cache key
  const cacheKey = getCacheKey(url, options);

  if (disableCache) {
    memoryCache.del(cacheKey);
  }

  // Retrieve from cache
  if (useCache && memoryCache && memoryCache.get(cacheKey)) {
    // console.log(
    //   "\x1b[32m",
    //   `API: Cached call to => ${url} (${cacheKey})`,
    //   "\x1b[0m",
    // );

    return new Promise((resolve) => resolve(memoryCache.get(cacheKey)));
  }
  // Make new request
  else {
    //console.log("\x1b[31m", `OLD API: Real call to => ${url}`, "\x1b[0m");

    const promiseFn = () =>
      fetch(url, options)
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then((data) => {
          if (useCache) {
            if (cacheDuration > 0) {
              // console.log(
              //   "\x1b[32m",
              //   `API: SET Cache => ${url} (${cacheKey})`,
              //   "\x1b[0m",
              // );
              memoryCache.set(cacheKey, data, cacheDuration);
            } else {
              memoryCache.set(cacheKey, data);
            }
          }
          return data;
        })
        .catch((error) => {
          if (error instanceof Error) {
            throw error;
          }

          return error
            .json()
            .then((responseJson) => {
              if (error.status !== 400) {
                console.info(
                  `HTTP (${url}) ${error.status} ${error.statusText}: ${responseJson.message}`,
                );
              }

              if (responseJson && responseJson.message) {
                throw new Error(`${responseJson.message}`);
              } else {
                throw error;
              }
            })
            .catch((err) => {
              // If error in reading json.
              if (err.type && err.type == "invalid-json") {
                // Throw parent error
                throw error;
              }

              throw err;
            });
        });

    const opts = {
      // The number of times the lib will retry execute the promiseFn
      // Default: 1
      times: 1,

      // The first wait time to delay
      // Default: 100
      initialDelayTime: 200,

      // (Optional) This callback is executed on each retry. It's useful to log your errors to a log service for example
      // Default: null
      onRetry: (error) => {
        console.error("== RETRYING API CALL (" + url + ") ==");
        console.error(error);
      },
      shouldRetry: (error) => {
        if (error) {
          if (error?.message?.includes("User doesn't exist")) {
            return false;
          } else {
            return true;
          }
        }
      },
    };

    // call retry passing promiseFn argument. Thats it!
    return retry(promiseFn, opts);
  }
};

export const callAPIForSWR = (
  endpoint: string,
  userId?: string,
  newProxy = false,
  revalidate?: number,
): Promise<any> => {
  const fortKnoxHeader = getFortKnoxHeader(userId);

  let API_BASE_URL = getBaseURLforAPI("client");

  if (newProxy) {
    API_BASE_URL = API_BASE_URL.replace("/proxy", "/new-proxy");
  }

  const url = constructURLforAPI(endpoint, null, API_BASE_URL);

  let cacheHeader = null;

  if (url.indexOf("/likes/") && !userId) {
    cacheHeader = getCacheHeader(userId);
  }

  const options: FetchOptions = {
    method: "GET",
    headers: {
      ...fortKnoxHeader,
      ...cacheHeader,
    },
    signal: AbortSignal.timeout && AbortSignal.timeout(3000),
  };

  // Add Next.js cache options as a custom header
  if (!userId && revalidate && revalidate > 0) {
    options.headers["x-next-cache-options"] = JSON.stringify({
      revalidate: revalidate,
      tags: ["swr-data"],
    });
  }

  //console.log("\x1b[31m", `OLD API SWR: Real call to => ${url}`, "\x1b[0m");

  return fetch(url, options).then((res) => {
    return res.json();
  });
};

/**
 * Makes API calls that are needed on every page
 * @param {*} ctx - Context (see next.js docs on getInitialProps, search for ctx)
 */
export const callRequiredAPIs = async (
  accessToken: string,
  currentUrl?: string,
  disableCache = false,
  ctx?: next.NextPageContext | void,
): Promise<PageRequiredData> => {
  // Fetch other data

  //const API_BASE_URL = getBaseURLforAPI("server"); //process.env.API_BASE_URL + "/api";

  // const categories: Promise<CategoryCollection> = callAPI(
  //   "/categories",
  //   {
  //     pageSize: 5,
  //     sortColumn: "startpage",
  //     sortDirection: "desc",
  //     fields: "name,urlName,altName",
  //   },
  //   null,
  //   API_BASE_URL
  // );

  // const platforms: Promise<PlatformCollection> = callAPI(
  //   "/platforms",
  //   {
  //     pageSize: 8,
  //     usageFilter: 12,
  //     sortColumn: "globalUsage",
  //     sortDirection: "desc",
  //     fields: "globalUsage,platformType,name,urlName",
  //   },
  //   null,
  //   API_BASE_URL
  // );

  // Fetch metadata
  let uriAsBase64: string;

  //let urlToGet = "";

  if (ctx) {
    currentUrl =
      ctx.req && ctx.req.url
        ? addTrailingSlash(ctx.req.url)
        : addTrailingSlash(ctx.asPath);
  }

  if (typeof btoa === "undefined") {
    uriAsBase64 = Buffer.from(
      `https://alternativeto.net${currentUrl}`,
    ).toString("base64");
  } else {
    uriAsBase64 = btoa(`https://alternativeto.net${currentUrl}`);
  }

  // if (currentUrl && currentUrl.indexOf("/browse/search") > -1) {
  //   const parsed = queryString.parse(
  //     new URL("http://dummy.com" + currentUrl).search,
  //   );

  //   meta = new Promise((resolve) => {
  //     resolve({
  //       noIndex: true,
  //       canonical: currentUrl,
  //       htmlHeadTitle: "Search Result: " + (parsed && parsed.q),
  //       htmlMetaDesc: "",
  //       h1Title: "Search Result: " + (parsed && parsed.q),
  //       pageDescription: "",
  //       breadcrumbs: [{ name: "Home", url: "/" }],
  //       pageType: "Search",
  //     });
  //   });
  // } else {
  const meta: Promise<PageMeta> = getPageMetaData(
    accessToken,
    uriAsBase64,
    disableCache,
    ctx,
  );
  //}

  return await Promise.all([meta]).then(([meta]) => {
    return {
      meta,
    };
  });
};

/**
 * Returns an object containing the Authorization header
 *
 * @param accessToken - Current user's access token
 */
export const getBasicAuthorizationHeader = (usr: string, pwd: string): any =>
  usr
    ? {
        Authorization:
          "Basic " + Buffer.from(usr + ":" + pwd).toString("base64"),
      }
    : {};

export const callInternalAPIServerSide = async (
  url: string,
  method: HTTPMethod = "GET",
  body?: any | string,
): Promise<any> => {
  const { InternalAPI_Url, InternalAPI_Usr, InternalAPI_Pwd } = process.env;

  const authHeader = getBasicAuthorizationHeader(
    InternalAPI_Usr,
    InternalAPI_Pwd,
  );

  return callPlainAPI(InternalAPI_Url + url, method, body, false, 0, {
    ...authHeader,
    "Content-Type": "application/json",
  });
};

export const callPlainAPI = async (
  url: string,
  method: HTTPMethod = "GET",
  body?: any | string,
  useCache?: boolean,
  cacheTtl: number = 6000,
  headers?: {
    [key: string]: string;
  },
): Promise<any> => {
  const options: FetchOptions = {
    method,
    body: body ? JSON.stringify(body) : null,
    headers: headers,
  };

  const cacheKey = getCacheKey(url, options);

  if (url.indexOf("/api/auth/") > -1) {
    useCache = false;
  }

  //console.log("\x1b[31m", `OLD API PLAIN: Real call to => ${url}`, "\x1b[0m");

  if (useCache && memoryCache && memoryCache.get(cacheKey) && cacheTtl > 0) {
    return new Promise((resolve) => resolve(memoryCache.get(cacheKey)));
  } else {
    try {
      const res = await fetch(url, options);

      if (!res.ok) {
        throw new Error(`${res.status} ${res.statusText}`);
      }

      let data;
      if (res.headers.get("content-type")?.includes("application/json")) {
        data = await res.json();
      } else {
        data = await res.text();
      }

      if (useCache) {
        memoryCache.set(cacheKey, data, cacheTtl);
      }

      return data;
    } catch (err) {
      console.error(err);
      throw err; // Re-throw the error so it can be caught and handled by the calling code
    }
    // return fetch(url, options)
    //   .then((res) => {
    //     if (!res.ok) {
    //       throw new Error(`${res.status} ${res.statusText}`);
    //     }
    //     return res;
    //   })
    //   .then((res) => {
    //     if (res.headers.get("content-type").includes("application/json")) {
    //       return res.json();
    //     }

    //     return res;
    //   })
    //   .then((data) => {
    //     if (useCache) {
    //       memoryCache.set(cacheKey, data, 6000);
    //     }

    //     return data;
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });
  }
};

export const getPageMetaData = (
  accessToken: string,
  uriAsBase64: string,
  disableCache = false,
  ctx?: next.NextPageContext | void,
) => {
  let meta: Promise<PageMeta>;

  const API_BASE_URL = getBaseURLforAPI("server");

  if (uriAsBase64) {
    meta = callAPI(
      `/meta/page/`,
      { uriAsBase64 },
      accessToken,
      API_BASE_URL,
      "GET",
      null,
      disableCache,
    );
  }
  if (meta) {
    meta
      .then((metaData) => {
        if (metaData.error > 0 && ctx && ctx.res) {
          ctx.res.statusCode = 404;
        }

        if (metaData.redirectUrl && ctx && ctx.res) {
          ctx.res.writeHead(301, {
            Location: metaData.redirectUrl,
          });
          ctx.res.end();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return meta;
};
